import Vue from 'vue';
import Vuex from 'vuex';

import user from './modules/user';
import template from './modules/template';
import breadcrumbs from './modules/breadcrumbs';
import variables from './modules/variables';

Vue.use(Vuex);

export default new Vuex.Store({
    modules: {
        user,
        template,
        breadcrumbs,
        variables
    },
    strict: true
});
