import Vue from 'vue';
import VueRouter from 'vue-router';

Vue.use(VueRouter);

const routes = [
    {
        path: '/',
        name: 'auth',
        component: () => import(/* webpackChunkName: "auth" */ '../views/Auth.vue'),
        meta: {
            title: 'Авторизация'
        }
    },
    {
        path: '/templates',
        name: 'templates',
        component: () => import(/* webpackChunkName: "templates" */ '../views/Templates.vue'),
        meta: {
            title: 'Шаблоны'
        },
        children: [
            {
                path: ':page',
                component: () => import(/* webpackChunkName: "templates" */ '../views/Templates.vue')
            }
        ]
    },
    {
        path: '/template/:id',
        name: 'templateView',
        component: () => import(/* webpackChunkName: "template" */ '../views/template/View.vue'),
        meta: {
            title: '{name}',
            family: 'template',
            parent: 'templates'
        },
        children: [
            {
                path: '',
                name: 'template',
                component: () => import(/* webpackChunkName: "template" */ '../views/template/Template.vue'),
                meta: {
                    title: '{name}',
                    family: 'template',
                    parent: 'templates'
                }
            },
            {
                path: 'version/:version',
                name: 'version',
                component: () => import(/* webpackChunkName: "template" */ '../views/template/Template.vue'),
                meta: {
                    family: 'template'
                }
            },
            {
                path: 'versions',
                name: 'versions',
                component: () => import(/* webpackChunkName: "versions" */ '../views/template/Versions.vue'),
                meta: {
                    title: 'Версии',
                    family: 'template'
                },
                children: [
                    {
                        path: ':page',
                        component: () => import(/* webpackChunkName: "versions" */ '../views/template/Versions.vue')
                    }
                ]
            },
            {
                path: 'variables',
                name: 'variables',
                component: () => import(/* webpackChunkName: "variables" */ '../views/template/Variables.vue'),
                meta: {
                    title: 'Переменные',
                    family: 'template'
                }
            },
            {
                path: 'settings',
                name: 'settings',
                component: () => import(/* webpackChunkName: "settings" */ '../views/template/Settings.vue'),
                meta: {
                    title: 'Настройки',
                    family: 'template'
                },
                children: [
                    {
                        path: ':version',
                        component: () => import(/* webpackChunkName: "settings" */ '../views/template/Settings.vue'),
                        meta: {
                            family: 'template'
                        }
                    }
                ]
            }
        ]
    },
    {
        path: '/create-template',
        name: 'createTemplate',
        meta: {
            title: 'Создать шаблон',
            parent: 'templates'
        },
        component: () => import(/* webpackChunkName: "createTemplate" */ '../views/TemplateCreate.vue')
    },
    {
        path: '/edit-template/:id',
        name: 'editTemplate',
        meta: {
            title: 'Редактировать шаблон',
            parent: 'templateView'
        },
        component: () => import(/* webpackChunkName: "editTemplate" */ '../views/TemplateEdit.vue')
    }
];

const router = new VueRouter({
  routes
});

export {
    routes,
    router
};
