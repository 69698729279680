
  import Vue from 'vue';

  import mixins from '@/mixins/index.ts';

  import RCP from '@/api/RCP';

  import MainMenu from '@/components/MainMenu.vue';
  import Breadcrumbs from '@/components/Breadcrumbs.vue';
  import Auth from '@/components/AuthWindow.vue';

  import Notifications from 'vue-notification';

  Vue.use(Notifications);

  Vue.mixin(mixins);

  import { routes } from '@/router';

  import { User, UserResponse } from '@/types/User';

  export default Vue.extend({
      name: 'documents',
      created() {
          this.isAuth();
          this.createRouteMap();
          console.log('v1.0.0');
      },
      data() {
          return {
              routeMap: new Map()
          };
      },
      computed: {
          user(): User {
              return this.$store.state.user.info;
          },
          authWindow(): boolean {
              return this.$store.state.user.authWindow;
          }
      },
      watch: {
          $route(to, from) {
              if (!(to.meta && to.meta.family && from.meta && from.meta.family && to.meta.family === from.meta.family)) {
                  window.scrollTo(0, 0);
              }

              this.$store.commit('breadcrumbs/clear');

              let flag = true;

              const checkParent = (route: any) => {
                  if (route.meta && route.meta.parent) {
                      const parent = this.routeMap.get(route.meta.parent);
                      const parentPath = parent.path.replace(':id', this.$route.params.id);

                      checkParent(parent);

                      this.$store.commit('breadcrumbs/set', {
                          title: parent.meta.title,
                          path: parentPath
                      });
                  }
              };

              to.matched.forEach((item: any) => {
                  if (flag) {
                      checkParent(item);
                      flag = false;
                  }

                  if (item.meta.title) {
                      const path = item.path.replace(':id', this.$route.params.id);

                      this.$store.commit('breadcrumbs/set', {
                          title: item.meta.title,
                          path
                      });
                  }
              });
          }
      },
      methods: {
          isAuth() {
              RCP({
                  method: 'Auth.user',
                  id: 'isAuth'
              }).then((result: UserResponse) => {
                  if (result.user) {
                      this.$store.commit('user/set', result.user);
                      if (this.$route.name === 'auth') {
                          this.$router.push('/templates');
                      }
                  } else {
                      if (this.$route.name !== 'auth') {
                          this.$router.push('/');
                      }
                  }
              });
          },
          createRouteMap() {
              this.routeMap = new Map(routes.map((item) => {
                  return [item.name, item];
              }));
          }
      },
      components: {
          MainMenu,
          Breadcrumbs,
          Auth
      }
  });
