
    import Vue from 'vue';
    import Field from '@/components/Field.vue';

    import RCP from '@/api/RCP.ts';

    import Validator from '@/helpers/validator';

    import { User } from '@/types/User';
    import { ErrorResponse } from '@/types/Errors';

    export default Vue.extend({
        name: 'authWindow',
        props: {
            background: {
                type: Boolean,
                default: false
            },
            title: {
                type: String,
                default: 'Авторизация'
            },
            description: {
                type: String,
                default: ''
            }
        },
        data() {
            return {
                form: {
                    login: '',
                    password: ''
                },
                formRules: {
                    login: 'required',
                    password: 'required'
                },
                errors: {
                    login: '',
                    password: ''
                },
                formRequest: false,
                request: false,
                error: ''
            };
        },
        created() {
            if (this.user.login) {
                this.form.login = this.user.login;
            }
        },
        computed: {
            user(): User {
                return this.$store.state.user.info;
            }
        },
        methods: {
            validate(submit = true) {
                if (!this.request) {
                    const validate = new Validator(this.form, this.formRules).make();

                    this.errors = validate.errors;

                    if (validate.isValid && submit) {
                        this.submit();
                    } else {
                        this.formRequest = true;
                    }
                }
            },
            submit() {
                this.request = true;
                this.error = '';

                RCP({
                    method: 'Auth.login',
                    params: this.form
                }).then((result: any) => {
                    if (result.user) {
                        if (this.$route.path === '/') {
                            this.$router.push('/templates');
                        }
                        this.$store.commit('user/set', result.user);
                        this.$store.commit('user/closeAuth');
                    }

                    this.request = false;
                }).catch((error: ErrorResponse) => {
                    this.error = error.message;

                    this.request = false;
                });
            }
        },
        watch: {
            formRequest(val) {
                if (val) {
                    this.$watch('form', () => {
                        this.validate(false);
                    }, {
                        deep: true
                    });
                }
            }
        },
        components: {
            Field
        }
    });
