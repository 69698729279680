export default {
    state: {
        info: {},
        authWindow: false
    },
    mutations: {
        set(state: any, user: any) {
            state.info = user;
        },
        clear(state: any) {
            state.info = {};
        },
        openAuth(state: any) {
            state.authWindow = true;
            document.body.style.overflow = 'hidden';
        },
        closeAuth(state: any) {
            state.authWindow = false;
            document.body.style.overflow = 'auto';
        }
    },
    namespaced: true
};
