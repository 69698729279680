export default {
    state: {
        history: [],
        notReplaced: [],
        variables: {}
    },
    mutations: {
        set(state: any, path: any) {
            const latIndex = state.history.length - 1;

            if (latIndex < 0 || state.history[latIndex].path + '/' !== path.path) {
                state.history.push(path);
                state.notReplaced.push(path);
                // @ts-ignore
                this.commit('breadcrumbs/replace');
            }
        },
        setVariable(state: any, variable: any) {
            state.variables[variable.name] = variable.value;
            // @ts-ignore
            this.commit('breadcrumbs/replace');
        },
        replace(state: any) {
            const variables = Object.keys(state.variables);

            if (state.history.length > 0 && variables.length > 0) {
                let history = state.notReplaced.map((item: any) => item.title).join('\n');

                variables.forEach((key) => {
                    history = history.replace('{' + key + '}', state.variables[key]);
                });

                history = history.split('\n');

                state.history = state.history.map((item: any, index: any) => {
                    return {
                        title: history[index],
                        path: item.path
                    };
                });
            }
        },
        clear(state: any) {
            state.history = [];
            state.notReplaced = [];
        }
    },
    namespaced: true
};
