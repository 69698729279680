export default class Validator {

    private errors: any;
    private formData: any;
    private rules: any;
    private valid: boolean;

    constructor(formData: any, rules: any) {
        this.formData = formData;
        this.rules = rules;

        this.errors = {};
        this.valid = true;
   }

    public make() {
        const inst = this;

        for (const prop in inst.formData) {
            if (inst.rules[prop]) {
                const propRules = inst.rules[prop].split('|');

                let failed = false;
                let i = 0;

                while (i < propRules.length && !failed) {
                    const rulerArr: any = propRules[i].split(':');
                    // @ts-ignore
                    if (typeof inst[rulerArr[0]] === 'function') {
                        // @ts-ignore
                        const test = inst[rulerArr[0]](prop, rulerArr[1]);
                        if (!test) {
                            inst.valid = false;
                            failed = true;
                            inst.errors[prop] = inst.getErrorText(rulerArr[0], rulerArr[1]);
                        } else {
                            inst.errors[prop] = '';
                        }
                    }
                    i++;
                }
            }
        }

        return {
            isValid: inst.valid,
            errors: inst.errors
        };
    }

    private email(propName: any) {
        const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return re.test(this.formData[propName]);
    }

    private required(propName: any) {
        const val = this.formData[propName];

        if (Array.isArray(val)) {
            return val.length > 0;
        } else if (typeof val === 'object') {
            if (Object.prototype.toString.call(val) === '[object Date]') {
                return true;
            }
        } else {
            return val !== '' && val !== false;
        }
    }

    private boolRequired(propName: any) {
        const val = this.formData[propName];

        return (val === true || val === false);
    }

    private getErrorText(ruleName: any, ruleValue: any) {
        if (ruleName === 'required' || ruleName === 'boolRequired') {
            return 'Поле обязательно';
        }
    }
}
