
    import Vue from 'vue';

    export default Vue.extend({
        name: 'field',
        model: {
            prop: 'model',
            event: 'change'
        },
        props: {
            numFilter: {
                type: Boolean,
                default: false
            },
            onlyNumFilter: {
                type: Boolean,
                default: false
            },
            title: {
                type: String,
                default: 'Значение'
            },
            hideName: {
                type: Boolean,
                default: false
            },
            type: {
                type: String,
                default: 'text'
            },
            model: {
                default: ''
            },
            error: {
                type: String,
                default: ''
            },
            desc: {
                type: String,
                default: ''
            },
            lazy: {
                type: Boolean,
                default: false
            }
        },
        computed: {
            val: {
                get(): string {
                    if (this.model === null) {
                        return '';
                    }

                    return this.model.toString();
                },
                set(v: string) {
                    if (this.numFilter && v.trim() === '.') {
                        this.$emit('change', '0.');
                        return;
                    }
                    this.$emit('change', v);
                }
            }
        }
    });
