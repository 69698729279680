import api from '@/api/axiosFactory';
import store from '@/store';

export default function(data: any): Promise<any> {
    return new Promise((resolve, reject) => {
        api({
            url: '/api/v1',
            method: 'post',
            data
        }).then((response: any) => {
            if (Array.isArray(data)) {
                const responses = response.data.map((item: any) => {
                    if (item.error) {
                        const show = item.error.code !== 403;

                        if (!show) {
                            store.commit('user/openAuth');
                        }

                        reject({
                            code: item.error.code,
                            message: item.error.message,
                            show
                        });
                    }

                    return item.result;
                });

                resolve(responses);
            }

            if (response.data.error && response.data.error.message) {
                const show = response.data.error.code !== 403;

                if (!show) {
                    store.commit('user/openAuth');
                }

                reject({
                    code: response.data.error.code,
                    message: response.data.error.message,
                    show
                });
            }

            resolve(response.data.result);
        }).catch((e: any) => {
            console.log(e);

            reject({
                code: 0,
                message: 'Отсутствует подключение к интернету',
                show: true
            });
        });
    });
}
