
    import Vue from 'vue';

    import RCP from '@/api/RCP.ts';
    import { User } from '@/types/User';
    import { ErrorResponse } from '@/types/Errors';

    export default Vue.extend({
        name: 'mainMenu',
        computed: {
            user(): User {
                return this.$store.state.user.info;
            }
        },
        methods: {
            logout() {
                RCP({
                    method: 'Auth.logout'
                }).then(() => {
                    this.$store.commit('user/clear');
                    this.$router.push('/');
                }).catch((error: ErrorResponse) => {
                    if (error.show) {
                        alert('Ошибка : ' + error.message);
                    }
                });
            }
        }
    });
