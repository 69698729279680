import { Template } from '@/types/Template';
import { Version } from '@/types/Version';

export default {
    state: {
        info: {},
        version: {}
    },
    mutations: {
        set(state: any, info: Template) {
            state.info = info;

            // @ts-ignore
            this.commit('breadcrumbs/setVariable', {
                name: 'name',
                value: state.info.name
            });
        },
        setVersion(state: any, version: Version) {
            state.version = version;
        },
        clear(state: any) {
            state.info = {};
            state.version = {};
        }
    },
    namespaced: true
};
